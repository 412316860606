import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { FrontDesk_URL, FrontDesk_Cyclotron_URL } from '../../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import InputGroup from 'react-bootstrap/InputGroup';
import { Spinner } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DownloadToolTip from '../UI/ToolTips/DownloadToolTip';
import SelfInactiveToolTip from '../UI/ToolTips/SelfInactiveToolTip';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { CSVLink, CSVDownload } from "react-csv";
import * as XLSX from 'xlsx';
import LoadingButton from '../commonComponent/LoadingButton';
import ToolTip from '../commonComponent/ToolTip';

var glblDocArr = [];
const SaleRegister = () => {
    const [centres, setCentres] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [defaultDate, setDefaultDate] = useState("");
    const [tableData, setTableData] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [cencelInvoiceNo, setcencelInvoiceNo] = useState(0);
    const [cid, setCID] = useState("0");
    const [modal, setModal] = useState();
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [CSVData, setCSVData] = useState([]);
    const [viewreqfieldsModal, setViewReqFieldsModal] = useState();
    const [visitid, setVisitId] = useState("0");
    const [tabledocdata1, setTableDocData1] = useState([]);
    const [document, setDocument] = useState("");
    const [doccrntPageNO1, setDocCrntPageNO1] = useState(0);
    const [doccrntPageNO, setDocCrntPageNO] = useState(0);
    const [tabledocdata, setTableDocData] = useState([]);

    let centreid = useRef();
    let fromDate = useRef();
    let toDate = useRef();
    let rfCancelReason = useRef();
    const toggleModal = () => {
        setModal(!modal)
    }
    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        //GetPartnerCentre("0", "0");
    }, []);

    const GetPartnerCentre = async (CentreTypeID, PartnerTypeId) => {
        try {
            let url = FrontDesk_URL + 'master/getpartenercentre_cyclrtonlist/' + CentreTypeID + '/' + PartnerTypeId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        const TypeOptionDs = [];
                        ds.map((option) => {
                            const TypeOptions = { 'id': option.CentreID, 'name': option.CentreName }
                            return TypeOptionDs.push(TypeOptions);
                        });
                        setCentres(TypeOptionDs);
                    }
                    else { setCentres([]); }
                }
                else {
                    setCentres([]);
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetRateTypeByPatientType = async (option) => {
        var Centrecode = 0;
        const idval = (option.length > 0) ? option[0].id : '';
        const TypeOpt = [];
        option.map((item) => {
            const TypeOptrows = { 'CentreID': item.id }
            return TypeOpt.push(TypeOptrows);
        });
        Centrecode = idval;
        setCID(Centrecode);
    }
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <DownloadToolTip title="Download Invoice" id={`DownloadInvoice${rowData.VisitingID}`} placement="top" onClick={() => DownloadHandler(rowData)} /> &nbsp;&nbsp;
            {/* {
                rowData.InvoiceStatus.toLowerCase() == "active" && rowData.IsCancelAllow == "1" ?
                    <ToolTip title={rowData.InvoiceType == "1" ? "Cancel Invoice" : "Cancel Credit Note"} placement="top" onClick={() => CancelHandler(rowData)} linkId={`CancelInvoice${rowData.VisitingId}`} faName="fa fa-window-close-o"></ToolTip>
                    : null
            } */}
            &nbsp;&nbsp;
            <ToolTip title="Upload Document" placement="top" onClick={() => openSaveRequiredFieldModal(rowData.VisitingID)} linkId={`Upload${rowData.VisitingID}`} faName="fa fa-upload"></ToolTip>
            &nbsp;&nbsp;
            {/* {
                rowData.InvoiceType == "1" && (rowData.GST_Number == "" || rowData.GST_Number == null || rowData.GST_Number == undefined) && (rowData.EwbNo == "" || rowData.EwbNo == null || rowData.EwbNo == undefined) ?
                    <ToolTip title="Generate Eway Bill" placement="top" onClick={() => ReavailEwaybill(rowData.VisitingID, rowData.CentreId)} linkId={`EBill${rowData.VisitingID}`} faName="fa fa-refresh"></ToolTip>
                    : null
            } */}
            {
                rowData.InvoiceType == "1" && (rowData.EwbNo == "" || rowData.EwbNo == null || rowData.EwbNo == undefined) ?
                    <ToolTip title="Generate Eway Bill" placement="top" onClick={() => ReavailEwaybill(rowData.VisitingID, rowData.CentreId)} linkId={`EBill${rowData.VisitingID}`} faName="fa fa-file"></ToolTip>
                    : null
            }
        </React.Fragment>
    }
    const EwbFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <span>{rowData.EwbNo}</span>  <br />
            {
                rowData.EwbInvoiceStatus.toLowerCase() == "active" && rowData.IsCancelAllow == "1" ? <SelfInactiveToolTip title="Cancel Ewb Invoice" id={`CancelEwbInvoice${rowData.VisitingId}`} placement="top" onClick={() => CancelEwbHandler(rowData)} /> : null
            }
        </React.Fragment>
    }
    const TypeFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            {rowData.InvoiceType == "1" ? "Invoice" : "Credit Note"}
        </React.Fragment>
    }
    const paginationOptions = { sizePerPage: 50, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last', onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); } };
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'BranchName', text: 'Branch Name', editable: false },
        { dataField: 'CentreName', text: 'Client Name', editable: false },
        { dataField: 'State', text: 'State', editable: false },
        { dataField: 'GST_Number', text: 'GST Number', editable: false },
        { dataField: 'InvoiceType', text: 'Type', editable: false, formatter: TypeFormatter },
        { dataField: 'VisitingCode', text: 'Visiting Code', editable: false },
        { dataField: 'InvoiceNo', text: 'Invoice Number', editable: false },
        { dataField: 'EwbNo', text: 'EWB Number', editable: false, formatter: EwbFormatter },
        { dataField: 'InvoiceAmount', text: 'Amount', editable: false },
        { dataField: 'InvoiceDate', text: 'Date', editable: false },
        { dataField: 'InvoiceStatus', text: 'Status', editable: false },
        { dataField: 'TaxableAmount', text: 'Taxable Amount', editable: false },
        { dataField: 'TaxRate', text: 'Tax Rate', editable: false },
        { dataField: 'TaxAmount', text: 'Tax Amount', editable: false },
        { dataField: 'IGSTAmount', text: 'IGST Amount', editable: false },
        { dataField: 'CGSTAmount', text: 'CGST Amount', editable: false },
        { dataField: 'SGSTAmount', text: 'SGST Amount', editable: false },
        { dataField: 'DocTotal', text: 'Doc Total', editable: false },
        { dataField: 'Action', text: 'Action', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '10%' }; } }
    ];
    const BindGrid = async () => {
        // if (cid == undefined || cid == null || cid == "" || cid == "0") {
        //     SweetAlert.fire({ title: "Required", text: "Please select centre", icon: "error" }); setIsLoading(false);
        // }
        if (fromDate.current.value == undefined || fromDate.current.value == null || fromDate.current.value == "" || fromDate.current.value == "0") {
            SweetAlert.fire({ title: "Required", text: "Please enter from date", icon: "error" }); setIsLoading(false);
        }
        else if (toDate.current.value == undefined || toDate.current.value == null || toDate.current.value == "" || toDate.current.value == "0") {
            SweetAlert.fire({ title: "Required", text: "Please enter to date", icon: "error" }); setIsLoading(false);
        }
        else {
            setIsLoading(true);
            const ObjBulkInvoiceReq = {
                CentreId: cid,
                FromDate: fromDate.current.value,
                ToDate: toDate.current.value,
                Type: "1"
            };
            let url = FrontDesk_URL + 'master/GetBulkInvoiceLists/';
            await axios.post(url, ObjBulkInvoiceReq).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success) {
                    if (data.Data !== null) { setTableData(data.Data); } else { setTableData([]); }
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
        }
    }
    const CancelHandler = async (rowData) => {
        SweetAlert.fire({
            title: 'You are going to cancel invoice. Are you sure?',
            text: "Once canceled, you will not be able to recover!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => { if (result.value) { CancelInvoice(rowData.VisitingID); } else { SweetAlert.fire('Your data is safe!') } })
    }
    const CancelEwbHandler = async (rowData) => {
        SweetAlert.fire({
            title: 'You are going to cancel EWB invoice. Are you sure?',
            text: "Once canceled, you will not be able to recover!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => { if (result.value) { setcencelInvoiceNo(rowData.VisitingID); setModal(!modal) } else { SweetAlert.fire('Your data is safe!') } })
    }
    const CancelInvoice = async (VisitingID) => {
        const requestOptions = {
            VisitingID: VisitingID,
            ModifiedBy: localStorage.LoggedInUser
        };
        let url = FrontDesk_URL + 'master/CancelEInvoiceIRNNo'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data == "2000") {
                    SweetAlert.fire({ title: "Success", text: "e-Invoice cancelled successfully", icon: "success" });
                    BindGrid();
                }
                else {
                    SweetAlert.fire({ title: "Application Exception", text: data.ErrorList[0].errorMsg, icon: "error" });
                }
            }
            else {
                SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" });
                setIsLoading(false);
            }
        }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
    }
    const CancelEwbInvoice = async () => {
        if (rfCancelReason == undefined || rfCancelReason.current.value == "") {
            SweetAlert.fire({ title: "Cancelation Error", text: "Please select cancelation reason", icon: "error" });
        }
        else {
            const requestOptions = {
                VisitingID: cencelInvoiceNo,
                CancelRsnCode: rfCancelReason.current.value,
                CancelRmrk: "",
                ModifiedBy: localStorage.LoggedInUser
            };
            let url = FrontDesk_URL + 'master/CancelEWayBill'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success) {
                    if (data.Data == "2000") {
                        setcencelInvoiceNo(0);
                        SweetAlert.fire({ title: "Success", text: "Ewb Invoice cancelled successfully", icon: "success" });
                        BindGrid();
                        setModal(!modal);
                    }
                    else {
                        SweetAlert.fire({ title: "Application Exception", text: data.ErrorList[0].errorMsg, icon: "error" });
                        setModal(!modal);
                    }
                }
                else {
                    SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" });
                    setIsLoading(false);
                    setModal(!modal)
                }
            }).catch(function (error) { setModal(!modal); setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        }
    }
    const DownloadHandler = async (rowData) => {
        const stateparams = { VisitingId: rowData.VisitingID, BuyerCentreId: rowData.CentreId, CreatedBy: localStorage.LoggedInUser };
        localStorage.removeItem('CyclotronData');
        localStorage.setItem('CyclotronData', JSON.stringify(stateparams));
        window.open(`${process.env.PUBLIC_URL}/pdfPrints/PrintCyclotronInvoice`, '_blank');
    }
    const DownloadSaleRegisterStatement = async () => {
        setDownloadVisible(false);
        // if (cid == undefined || cid == null || cid == "" || cid == "0") {
        //     SweetAlert.fire({ title: "Required", text: "Please select centre", icon: "error" }); setIsLoading(false);
        // }
        if (fromDate.current.value == undefined || fromDate.current.value == null || fromDate.current.value == "" || fromDate.current.value == "0") {
            SweetAlert.fire({ title: "Required", text: "Please enter from date", icon: "error" }); setIsLoading(false);
        }
        else if (toDate.current.value == undefined || toDate.current.value == null || toDate.current.value == "" || toDate.current.value == "0") {
            SweetAlert.fire({ title: "Required", text: "Please enter to date", icon: "error" }); setIsLoading(false);
        }
        else {
            setIsButtonLoading(true);
            const ObjBulkInvoiceReq = {
                CentreId: cid,
                FromDate: fromDate.current.value,
                ToDate: toDate.current.value,
                Type: "2"
            };
            let url = FrontDesk_URL + 'master/GetBulkInvoiceLists/';
            await axios.post(url, ObjBulkInvoiceReq).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    if (data.Data == null) { setDownloadVisible(false); setCSVData([]); SweetAlert.fire("No record found"); }
                    else {
                        setCSVData(data.Data);
                        setDownloadVisible(true);
                        document.getElementById("csvdownload").click();
                    }
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setCSVData([]); setDownloadVisible(false); setIsButtonLoading(false); }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
    }
    const openSaveRequiredFieldModal = (VisitingId) => {
        setDocument("");
        glblDocArr = [];
        setTableDocData1([]);
        setVisitId(VisitingId);
        openRequiredFieldModal();
        ShowAllDocuments(VisitingId);
    }
    const openRequiredFieldModal = () => { setViewReqFieldsModal(!viewreqfieldsModal); }
    const ShowAllDocuments = async (vId) => {
        try {
            let url = FrontDesk_URL + 'master/getalldocuments_cyclotron/' + vId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        setTableDocData(data.Data);
                    }
                    else { setTableDocData([]); }
                }
                else {
                    setTableDocData([]);
                    if (data.data != null) { SweetAlert.fire("Error: " + data.Data); }
                    else { SweetAlert.fire("Some error occured. Check logs!"); }
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const UploadDocument = async (e) => {
        const arryVal = e.target.files[0].name.split(".");
        const extension = arryVal[arryVal.length - 1];
        var base64 = await toBase64(e.target.files[0]);
        var base64img = base64.split("base64,");
        const formData = new FormData();
        formData.append('Image', base64img[1]);
        formData.append('FolderName', 'cyclotrondocument');
        formData.append('pathflag', 'cyclotrondocument');
        const ImageName = Math.random();
        var imgName = imgName = ImageName + "cyclotron." + extension;
        formData.append('ImageName', imgName);
        let url = FrontDesk_URL + 'master/UploadImage_Root/'
        await axios.post(url, formData, {
            headers: { "Content-type": "multipart/form-data", },
        }).then((response) => {
            const data = response.data;
            if (data.Success) {
                var key = glblDocArr.length;
                glblDocArr.push({
                    RowId: (parseInt(key) + 1).toString(),
                    VisitingId: visitid,
                    Type: extension,
                    Document: imgName
                });
                setDocument(imgName);
                setTableDocData1(glblDocArr);
            } else { SweetAlert.fire({ title: "Upload failed", text: "Unable to upload document due to " + data.Data + ".Please retry again", icon: "error" }) }
        }).catch(function (error) { if (error.response) { } });
    }
    const iddocFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{doccrntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const iddocFormatter1 = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{doccrntPageNO1 + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const docFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <button className='btn btn-link' style={{ color: '#e31e25' }} id={`Documents${rowData.RowId}`} onClick={() => OpenDocument(rowData.VisitingId, rowData.Document)}>View Document</button>
            </div>
        </React.Fragment>
    }
    const docFormatter1 = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <button className='btn btn-link' style={{ color: '#e31e25' }} id={`Documents${rowData.RowId}`} onClick={() => OpenDocument(rowData.VisitingId, rowData.Document)}>View Document</button>
            </div>
        </React.Fragment>
    }
    const docPaginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setDocCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const docPaginationOptions1 = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setDocCrntPageNO1(((page - 1) * sizePerPage)); }
    };
    const docColumns = [
        { dataField: '#', text: '#', editable: false, formatter: iddocFormatter, headerStyle: (colum, colIndex) => { return { width: '2%' }; } },
        { dataField: 'Type', text: 'Type', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'actions', text: 'Actions', editable: false, formatter: docFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const docColumns1 = [
        { dataField: '#', text: '#', editable: false, formatter: iddocFormatter1, headerStyle: (colum, colIndex) => { return { width: '2%' }; } },
        { dataField: 'Type', text: 'Type', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'actions', text: 'Actions', editable: false, formatter: docFormatter1, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const OpenDocument = async (VisitingId, Document) => {
        var prename = "CyclotronDocument_" + VisitingId;
        var preurl = FrontDesk_Cyclotron_URL + "cyclotrondocument/" + Document;
        window.open(preurl, '_blank');
        //downloadFile(preurl, prename);
    }
    function downloadFile(url, fileName) {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        aElement.href = url;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(url);
    }
    const SaveRequiredFields = async () => {
        if (tabledocdata1.length == 0 || tabledocdata1 == [] || tabledocdata1 == null) {
            SweetAlert.fire("Please upload document first");
            return false;
        }
        // if (cid == undefined || cid == null || cid == "" || cid == "0") {
        //     SweetAlert.fire({ title: "Required", text: "Please select centre", icon: "error" });
        // }
        var requestOptions = {
            CentreId: cid,
            LoginId: localStorage.LoggedInUser,
            VisitingId: visitid,
            PrescriptionDocument: JSON.stringify(tabledocdata1)
        };
        setIsButtonLoading(true);
        try {
            let url = FrontDesk_URL + 'master/savecyclotrondocument'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    const today = new Date();
                    const date = today.setDate(today.getDate());
                    const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                    setDefaultDate(defaultValue);
                    SweetAlert.fire({
                        title: 'Success',
                        text: "Data saved successfully",
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {
                            window.location.reload(false);
                            //openRequiredFieldModal();
                        }
                        else {
                            window.location.reload(false);
                            //openRequiredFieldModal();
                        }
                    })
                }
                else {
                    if (data.Data == "-2000") { SweetAlert.fire({ title: "Error", text: "Unable to save data.Check db logs", icon: "error" }); }
                    else { SweetAlert.fire({ title: "Error", text: "Unable to save data.Please try again", icon: "error" }); }
                }
            }).catch(function (error) {
                setIsButtonLoading(false);
                if (error.response) {
                    ErrorFunction(error.response.status);
                }
            });
        }
        catch (error) {
            setIsButtonLoading(false);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const ReavailEwaybill = async (VisitingID, BuyerCentreId) => {
        setIsPageLoading(true);
        const objInvWithoutEInvoiceFields = {
            VisitingID: VisitingID,
            BuyerCentreId: BuyerCentreId,
            CreatedBy: localStorage.LoggedInUser,
        };
        let url = FrontDesk_URL + 'master/ReavailEwaybill'
        await axios.post(url, objInvWithoutEInvoiceFields).then((response) => {
            setIsPageLoading(false);
            const data = response.data;
            if (data.Success && data.Data == "2000") {
                SweetAlert.fire({
                    title: 'Thank You!', text: "Invoice generated successfully.", icon: 'success', showCancelButton: false, confirmButtonText: 'Ok', reverseButtons: true
                }).then((result) => {
                    window.location.reload(false);
                })
            }
            else {
                if (data.ErrorList[0].errorCode == -2000 || data.Data == -2000) { SweetAlert.fire({ title: "API Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
            }
        }).catch(function (error) {
            setIsPageLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Sale Register" parent="Bulk Invoice" />
            <Container fluid>
                {isPageLoading ?
                    <Row className='mt25'>
                        <Col md={12} className='text-center'>
                            <Spinner animation="border" size='sm' />
                        </Col>
                    </Row>
                    :
                    <>
                        <Row className='mt25'>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Form>
                                            <Form.Row>
                                                {/* <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Centre Name<span className='text-red'>*</span></Form.Label>
                                                <Typeahead id="centreName" name="centreName"
                                                    defaultSelected={centres.slice(0, 5)}
                                                    labelKey="name"
                                                    ref={centreid}
                                                    options={centres}
                                                    onChange={GetRateTypeByPatientType}
                                                    placeholder="---Select---" />
                                            </Form.Group>
                                        </Col> */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>From Date<span className='text-red'>*</span></Form.Label>
                                                        <InputGroup className="mb-3 d-flex justify-content-start">
                                                            <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>To Date<span className='text-red'>*</span></Form.Label>
                                                        <InputGroup className="mb-3 d-flex justify-content-start">
                                                            <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} />
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row>
                                                <Col md={12} className='text-center __center__buttonsec'>
                                                    <Form.Group>
                                                        <Button className='ml-2' variant='primary' onClick={BindGrid} >Get Bulk Invoice</Button>
                                                        {isButtonLoading ? <LoadingButton variantName='secondary' cssName="" /> :
                                                            <Button variant='secondary' className='ml-2' onClick={DownloadSaleRegisterStatement}><i className='fa fa-file-excel-o'></i> Download</Button>
                                                        }
                                                        {/* <Button className='ml-2' variant='primary' onClick={BindGrid} >Download</Button> */}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Row>
                                                <Col md={12}>
                                                    <Card>
                                                        <Card.Header className='pb-0'>
                                                            <Card.Title>Sale Register</Card.Title>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            {
                                                                isLoading ?
                                                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                                    <>
                                                                        <DataTables
                                                                            keyField='InvoiceId'
                                                                            tableData={tableData}
                                                                            columns={columns}
                                                                            noDataIndication={NoRecordFounds}
                                                                            pagination={paginationFactory(paginationOptions)}
                                                                        />
                                                                    </>
                                                            }
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        {
                            downloadvisible &&
                            <CSVDownload data={CSVData} target="_blank" />
                            // <CSVLink data={CSVData} filename="Cyclotron-SaleRegister.csv" id="csvdownload" target="_blank">Download Me</CSVLink>
                        }
                    </>
                }
            </Container>
            {modal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true} >
                    <ModalHeader toggle={toggleModal}>EWB Cancel Invoice </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Form.Row className="justify-content-center">
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Ewb Cancel Reason<span className='text-required'>*</span></Form.Label>
                                                    <Form.Control as="select" name="cancelReason" ref={rfCancelReason} >
                                                        <option value="">---Select---</option>
                                                        <option value="1">Duplicate</option>
                                                        <option value="2">Order Cancelled</option>
                                                        <option value="3">Data Entry Mistake</option>
                                                        <option value="4">Others</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="primary" onClick={CancelEwbInvoice}>Submit</Button>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            }
            {
                viewreqfieldsModal &&
                <Modal isOpen={viewreqfieldsModal} toggle={openRequiredFieldModal} centered={true} size="lg">
                    <ModalHeader toggle={openRequiredFieldModal}>View/Upload Documents</ModalHeader>
                    <ModalBody className='modalvariation'>
                        {
                            tabledocdata == [] || tabledocdata == null || tabledocdata.length == 0 ? null :
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Header>
                                                <Row className='align-items-center'>
                                                    <Col xs={6}>
                                                        <h3 className='routeSN'>View Documents</h3>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <DataTables
                                                    keyField='RowId'
                                                    tableData={tabledocdata}
                                                    columns={docColumns}
                                                    noDataIndication={NoRecordFounds}
                                                    pagination={paginationFactory(docPaginationOptions)} />

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                        }
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header>
                                        <Row className='align-items-center'>
                                            <Col xs={6}>
                                                <h3 className='routeSN'>Upload Documents <span className='text-red'>(Max 5 mb accepted)</span></h3>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Col md={4}>
                                                <Form.Group>
                                                    <Form.Label>Upload Document <span className='text-red'>*</span></Form.Label>
                                                    <div><input type="file" id='up_docs' onChange={(e) => UploadDocument(e)} /></div>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                {
                                    document == "" || document == null ? null :
                                        <Row>
                                            <Col md={12}>
                                                <Card>
                                                    <Card.Header>
                                                        <Row className='align-items-center'>
                                                            <Col xs={6}>
                                                                <h3 className='routeSN'>View Uploaded Documents</h3>
                                                            </Col>
                                                        </Row>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <DataTables
                                                            keyField='RowId'
                                                            tableData={tabledocdata1}
                                                            columns={docColumns1}
                                                            noDataIndication={NoRecordFounds}
                                                            pagination={paginationFactory(docPaginationOptions1)} />
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className='text-center'>
                                <Button variant='secondary' className="mr-2" onClick={openRequiredFieldModal}>Cancel</Button>
                                {
                                    document == "" || document == null ? null :
                                        <>
                                            {
                                                isButtonLoading ?
                                                    <LoadingButton variantName='primary' cssName="" /> :
                                                    <Button variant='primary' type='submit' onClick={(e) => SaveRequiredFields()}>Save</Button>
                                            }
                                        </>
                                }
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            }
        </React.Fragment>
    )
}
export default SaleRegister